import { GetServerSideProps } from 'next';

import { PathName, supportAtta } from '@/features/atta';
import { OrderLookupPage } from '@/features/order-lookup/page';
import { ConnectorOrders, getConnectOrders } from '@/features/returns/resources/getConnectOrders';
import getStores from '@/features/returns/resources/getStores';
import { getShopifyAppKey, queryToString } from '@/features/returns/utils/common';

import { Order } from '../features/app-proxy-order-list/types';

const transformOrders = (orders?: ConnectorOrders['orders'] | null): Order[] =>
  orders?.map((order) => ({
    email: order.customer?.emails?.[0] ?? '',
    order_number: order.order_number,
    // metrics 可能为空
    created_at: order.metrics?.placed_at || '',
    products: order.items.map((item) => ({
      image_url: item.image_urls?.[0] ?? '',
      name: item.title,
      number: item.quantity,
      price: item.discounted_price_incl_tax_set?.presentment_money?.amount ?? 0,
      currency: item.discounted_price_incl_tax_set?.presentment_money?.currency ?? 'USD',
      variants: item.variant_title?.split('/') ?? [],
    })),
  })) ?? [];

export const getServerSideProps: GetServerSideProps = async (context) => {
  // If you request this page with the preview mode cookies set:
  //
  // - context.preview will be true
  // - context.previewData will be the same as
  //   the argument used for `setPreviewData`.
  const { preview, previewData } = context;

  const { isAppProxy } = await import('../../web/features/returns/utils/common');

  const isProxy = isAppProxy(context.res);

  const defaultProps: {
    isAppProxy: boolean;
    orders?: Order[];
  } = {
    isAppProxy: isProxy,
  };

  const { shop, logged_in_customer_id } = context.query;

  if (isProxy && logged_in_customer_id) {
    const appKey = getShopifyAppKey(String(shop));

    const res = await getStores(appKey);

    const organizationId = res.connections[0]?.organization.id;

    const { orders } = await getConnectOrders({
      customerId: queryToString(logged_in_customer_id),
      organizationId,
      appKey,
    });
    defaultProps.orders = transformOrders(
      orders?.filter((order) => order.customer.external_id === logged_in_customer_id) ?? [],
    );
  }

  if (preview) {
    return {
      props: {
        ...defaultProps,
        preview,
        previewData,
      },
    };
  }
  return {
    props: defaultProps, // will be passed to the page component as props
  };
};

export default supportAtta(OrderLookupPage, PathName.OrderLookup);
